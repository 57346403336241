import { Col, Row } from "antd";
import { useState } from "react";

const StudentQuestion = ({ question, index }) => {
  const [selectedAnswer, setSelectedAnswer] = useState("");

  const handleSelectAnswer = (order) => {
    setSelectedAnswer(order);
  };

  return (
    <div className="mb-10 text-right">
      <div className="border-2 border-secondary rounded-3xl p-6">
        <div className="text-3xl font-bold mb-6 flex items-center gap-6 justify-end">
          <div className="border-2 border-secondary rounded-3xl px-4 py-2">
            {question.difficulty}
          </div>
          {index + 1} السؤال رقم{" "}
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: question.question,
          }}
          className="border-b-2 border-secondary mb-10 py-6 "
        />
        <Row gutter={[20, 20]} style={{ direction: "rtl" }}>
          {question.answers.map((answer, i) => (
            <Col xs={24} sm={12} key={i}>
              <button
                onClick={() => handleSelectAnswer(answer.order)}
                className={` w-full border-2 border-secondary rounded-2xl p-4 cursor-pointer flex flex-col gap-6 transition-all  ${
                  question.correct_answer === selectedAnswer &&
                  answer.order === question.correct_answer &&
                  "bg-[#cddc39] text-white"
                } ${
                  selectedAnswer === answer.order &&
                  selectedAnswer !== question.correct_answer &&
                  "bg-secondary text-white"
                }`}
              >
                <p className="font-bold">الإجابة </p>
                <div
                  className="font-bold"
                  dangerouslySetInnerHTML={{
                    __html: answer.answer,
                  }}
                />
              </button>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default StudentQuestion;
